// ============================================================
// ATTENTION & COMMON RULE!!
// 関数を実装のみ（処理の実行は下部で実行する）
// 関数名には振る舞いを表す英語プラスFuncを付ける
// ============================================================

// レスポンシブ判定 & デバイス判定関数（PC,SP共通処理）
// -------------------------------------------------------------

function mq(device) {
  if (window.matchMedia("(max-width:768px)").matches && device === "sp") {
    return true;
  } else if (window.matchMedia("(min-width:769px)").matches && device === "pc") {
    return true;
  }
  return false;
}
// FUNCTIONS
function lazyLoadVideo() {
  var videoPc = document.getElementById("videoTop");
  var videoWrap = document.getElementById("videoWrap");
  var headerWrap = document.getElementById("headerWrap");
  var firstWrap = document.getElementById("firstWrap");
  var loadingWrap = document.getElementById("loadingWrap");
  var progressBar = document.getElementById("progressBar");
  var fLogo01 = document.getElementById("fLogo01");
  var fLogo02 = document.getElementById("fLogo02");
  var fLogo03 = document.getElementById("fLogo03");

  var xhr = new XMLHttpRequest();
  xhr.open("GET", "img/pc.mp4", true);
  xhr.responseType = "blob";
  console.log(xhr);
  xhr.addEventListener("progress", updateProgress);
  function updateProgress(oEvent) {
    if (oEvent.lengthComputable) {
      var percentComplete = (oEvent.loaded / oEvent.total) * 100;
      progressBar.style.width = percentComplete + "%";
      if (percentComplete === 100) {
        loadingWrap.classList.add("end");
      }
      console.log("percentComplete" + percentComplete);
    } else {
      // 全体の長さが不明なため、進捗情報を計算できない
    }
  }

  xhr.onload = function(e) {
    console.log("this.status" + this.status);
    if (this.status === 200) {
      // ロード完了
      var blob = this.response;

      var src = (window.webkitURL || window.URL).createObjectURL(blob);
      console.log("videoPc loaded!!!");
      videoPc.src = "img/pc.mp4";
      videoWrap.classList.add("show");
      videoPc.play();
      setTimeout(function() {
        firstWrap.classList.add("show");
        loadingWrap.classList.add("hide");
        fLogo01.classList.add("show");
        fLogo02.classList.add("show");
        fLogo03.classList.add("show");
        headerWrap.classList.add("show");
      }, 1000);
    }
  };
  xhr.send();
}

function topSpFunc() {
  var videoSp = document.getElementById("videoTop");
  var videoWrap = document.getElementById("videoWrap");
  var headerWrap = document.getElementById("headerWrap");
  var firstWrap = document.getElementById("firstWrap");
  var loadingWrap = document.getElementById("loadingWrap");
  var progressBar = document.getElementById("progressBar");
  var fLogo01 = document.getElementById("fLogo01");
  var fLogo02 = document.getElementById("fLogo02");
  var fLogo03 = document.getElementById("fLogo03");
  var menuTrigger = document.getElementById("menuTrigger");

  // 画像用配列
  var images = [
    "../sp-video.png"
  ];

  // 画像プリロード

  for (i = 0; i < images.length; i++) {
    var img = document.createElement("img");
    img.src = images[i];
    console.log(i + "と" + images.length);

    if (i === images.length - 1) {
      console.log("finish");
      initTop();
    }
  }
  function initTop() {
    console.log("in it");
    loadingWrap.classList.add("hide");

    setTimeout(function() {
      firstWrap.classList.add("show");
      loadingWrap.classList.add("hide");
      fLogo01.classList.add("show");
      fLogo02.classList.add("show");
      fLogo03.classList.add("show");
      headerWrap.classList.add("show");
    }, 1000);
  }
}

function subFunc() {
  var headerWrap = document.getElementById("headerWrap");
  var contentsWrap = document.getElementById("contentsWrap");
  headerWrap.classList.add("show");
  contentsWrap.classList.add("show");

  if ($("#itenSnapGallery").length) {
    $('#itenSnapGallery').magnificPopup({
      delegate: 'a', // child items selector, by clicking on it popup will open
      type: 'image',
      gallery:{enabled:true}
      // other options
    });
  }

}
// ============================================================
// ATTENTION & COMMON RULE!!
// まとめて関数実行（※必要に応じて条件分岐を入れる）
// ページ個別に処理をする場合は「ページ固有のID名.lengthで処理を分岐」
// ============================================================

// --- ページの全データを読み込み後 ----------------------------------
$(window).on("load", function() {
  // $(".sub-page .head-line").addClass("show");
});

// --- リサイズが走った場合 ----------------------------------------
$(window).on("resize", function() {});

// --- DOM生成後 -----------------------------------------------
$(function() {
  console.log("script.js");

  var observer = lozad(".lozad", {
    threshold: [0, 1.0],
    loaded: function(el) {
      // Custom implementation on a loaded element
      el.classList.add("loaded");
    }
  });
  // lazy loads elements with default selector as '.lozad'
  observer.observe({});

  if ($("#topPage").length) {
    var swiper = new Swiper("#galleryTop", {
      effect: "fade",
      speed: 3000,
      autoplay: {
        delay: 3000
      },
      loop: true
    });

    if (mq("sp")) {
      topSpFunc();
    } else {
      lazyLoadVideo();
    }
  } else {
    subFunc();
  }

  // SMOOTH SCROOL FOR CLASS NAME
  $("a.scroll").click(function() {
    console.log("TEST");
    $("html,body").animate({ scrollTop: $($(this).attr("href")).offset().top - 80 }, "slow", "swing");
    $("body").removeClass("menu-show");

    return false;
  });

  // SMOOTH SCROOL ATTRIBUTE NAME
  // $('a[href^="#"]').click(function() {
  //   var speed = 500;
  //   var href = $(this).attr("href");
  //   var target = $(href == "#" || href == "" ? "html" : href);
  //   var position = target.offset().top;
  //   $("html, body").animate({ scrollTop: position }, speed, "swing");
  //   return false;
  // });
  // MENU
  $("#menuTrigger").click(function() {
    if ($("body").hasClass("menu-show")) {
      $("body").removeClass("menu-show");
    } else {
      $("body").addClass("menu-show");
    }
  });

  $("#closeBtn").click(function() {
    $(".youtube-wrap").removeClass("show");
  });
  $("#youtubeBtn").click(function() {
    $(".youtube-wrap").addClass("show");
    return false;
  });

  // PC or SPサンプル
  if (mq("sp")) {
    console.log("Mobile");
  } else {
    console.log("Desktop");
  }
});

// SCROLL AND TOCH START ANIMATION
$(window).on("scroll", _.throttle(updatePosition, 250));

function updatePosition() {
  var headerWrap = document.getElementById("headerWrap");
  if ($(this).scrollTop() > 300) {
    headerWrap.classList.add("bg");
  } else {
    headerWrap.classList.remove("bg");
  }
}
